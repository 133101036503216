import React from 'react'

// Layout
import { Layout } from '@layouts'

const NotFoundPage = (props) => (
	<Layout props={props}>
		<h1>NOT FOUND</h1>
		<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
	</Layout>
)

export default NotFoundPage
